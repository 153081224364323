'format es6';
'use strict';

import { docReady } from './utils/docReady';
import $ from 'jquery';
import Promise from 'Promise';

function initSnippet(snippet) {
	const _snippet = $(snippet);
	const data = JSON.parse(_snippet.siblings('[data-share]').html());

	_snippet.find('[data-facebook]').on('click.jcv', function(){
		window.FB.ui({
			method: 'feed',
			link: data.url,
			name: data.name,
			picture: data.picture,
			description: data.description,
		});
	});

	_snippet.find('[data-twitter]').on('click.jcv', function(){
		var url = 'https://twitter.com/intent/tweet/?url='+encodeURI(data.url)+'&text='+encodeURIComponent(data.name);
		window.open(url, '', 'width=560,height=250');
	});
}

docReady.then(function(){
	$('[data-share-snippet]').each(function(i, snippet){
		initSnippet(snippet);
	});
});

export default {
	initSnippet:initSnippet,
};

'format es6';
'use strict';

import Prompts from '../utils/Prompts';
import Validate from '../utils/Validate';
import { factory as formFactory, bootstrapForm } from './Shop.form';

const FORM_SELECTOR = '#shop-checkout';

function setupCheckout(checkoutForm) {
	// console.log(checkoutForm);
	bootstrapForm(checkoutForm);
	
	//au load, affiche toutes les erreurs s'il y en a
	$('[data-error]').each((i, el) => {
		el = $(el);
		const err = el.data('error');
		if(err) Prompts.showElementError(el, err)
	});

	if(!checkoutForm.length) return;
	const url = checkoutForm.prop('action');
	const onPosted = this.setUpdater(checkoutForm);

	checkoutForm.on('submit.lagrange.shop', (e) => {
		e.preventDefault();

		const valid = Validate.checkForm(checkoutForm);
		if(!valid) return;

		const serialized = checkoutForm.serialize();
		this.doPost(url, serialized, onPosted);
	});
};

//run dès qu'includé dans la page (après le markup, pas besoin de doc.load)
formFactory(FORM_SELECTOR, setupCheckout);

export default {};

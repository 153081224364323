'format es6';
'use strict';

import Prompts from '../utils/Prompts';

import selectric from 'selectric';
selectric();

//prototype des formulaires ajax dont le output est du html

export default function AjaxForm(rootSelector, setup) {

	const rootNode = $(rootSelector);
	setup = setup.bind(this);

	//une fois le call ajax fait, remplace le root node par le rséultat
	this.setUpdater = (oldNode) => {
		return (res) => {
			// console.log(res);
			const updatedNode = $(rootSelector, res);
			
			//pas de result, probablement erreur majeure, on remplace le body au complet
			if (updatedNode.length === 0) {
				console.log('no updated');
				$('body').replaceWith($(res));
				return;
			}
			// console.log(res);
			// console.log(updatedNode.html());
			// updatedCart.css('border', '1px solid '+getRandomColor());
			//remplace aussi toutes les instances qui dépendent du cart (par ex. les qtys dans le cart)
			$('[data-shop-ajax]').each((i, oldElNode) => {
				const oldEl = $(oldElNode);
				const name = oldEl.data('shop-ajax');
				const newEl = $(`[data-shop-ajax="${name}"]`, res);
				oldEl.replaceWith(newEl);
			});

			oldNode.replaceWith(updatedNode);
			setup(updatedNode);

			Prompts.appendGlobalFromAjax(res);
		};
	};

	this.doPost = (url, data, onPosted, onPostError) => {
		// console.log(data);
		const posted = $.ajax({
			method:'POST',
			dataType: 'html',
			url: url,
			data: data
		});

		posted.then(
			onPosted,
			onPostError || ((jqXHR) => {
				console.log(jqXHR.responseText);
			})
		);
	};

	setup(rootNode);

	return this;

};

export function bootstrapForm(frm) {
	$('select[data-val]', frm).each((i, el) => {
		const select = $(el);
		select.val(select.data('val'));
	});

	$('select', frm).selectric({
		disableOnMobile:false
	});
}

export function factory(rootSelector, setup) {
	return AjaxForm.apply({}, [rootSelector, setup]);
};

'format es6';
'use strict';

import { factory as formFactory } from './Shop.form';

//nombre max qu'on peut acheter de chaque vin
const MAX_QTY = 72;
const CART_SELECTOR = '#shop-cart';


function setupQtySelects(cartNode){
	//si jq vide, on select au global
	const container = ( cartNode.length && cartNode ) || null;
	//le nombre de bouteilles qu'on peut choisir ds les select depend de en combien vient chaque vin
	$('[data-cart-qty]', container).each((i, el) => {
		const select = $(el);
		const val = Number(select.data('val'));
		const increment = Number(select.data('increment'));
		let n = 0;
		while(n < MAX_QTY){
			n += increment;
			select.append( $('<option value="'+n+'"'+(val===n && 'selected') +'>'+n+'</option>') );
		}

		select.selectric('refresh');

	});
}


function buildPost(name, val, post){
	post = post || {};
	post[name] = val;
	return post;
}

function setupCart(cartNode) {

	setupQtySelects(cartNode);

	if(!cartNode.length) return;
	const url = cartNode.data('post-url');
	const onPosted = this.setUpdater(cartNode);

	const postForm = (postData) => {
		this.doPost(url, postData, onPosted);
	};

	$('[data-submit-click]', cartNode).on('click.cart.autopost', (e) => {
		e.preventDefault();
		const clicked = $(e.currentTarget);
		postForm(buildPost(clicked.prop('name'), clicked.data('value')));
	});

	//auto refresh quantité quand change dropdown
	$('[data-submit-change]', cartNode).on('change.cart.autopost', (e) => {
		const inp = $(e.currentTarget);
		postForm(buildPost(inp.prop('name'), inp.val()));
	});

};

//run dès qu'includé dans la page (après le markup, pas besoin de doc.load)
formFactory(CART_SELECTOR, setupCart);

export default {};
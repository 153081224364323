'format es6';
'use strict';

const ARROW_H = 10;
const ANIM_TIME = 0.2;
const GLOBAL_PROMPT_SELECTOR = '#global-prompt';

import $ from 'jquery';
import Promise from 'Promise';
import gsap from 'greensock';

const TweenMax = gsap.TweenMax;
const ease = gsap.Expo;

function disappearGlobalPrompt(container) {
	const h = container.height();
	return new Promise((resolve) => {
		TweenMax.fromTo(container, ANIM_TIME, {
			top:0,
			ease:ease.easeIn
		}, {
			top : h * -1,
			onComplete: () => {
				container.empty();
				resolve();
			}
		});
	});
};

export default {

	//montre une erreur pour une element
	showElementError(el, msg) {

		const promptContent = $('<div class="element-error-content">').html(msg);
		const prompt = $('<div class="element-error">').append(promptContent);
		el.before(prompt);
		const elPos = el.position();
		const elW = el.width();
		const promptW = promptContent.outerWidth();
		const promptH = promptContent.outerHeight();
		prompt.css({
			top: elPos.top+'px',
			left: (elPos.left + elW - (promptW / 2))+'px',
			marginTop: (-1 * (promptH + ARROW_H))+'px'
		});

		function removePrompt(){
			prompt.remove();
			el.off('.prompt');
		}

		if(el.is(':input')) {
			el.on('focus.prompt', removePrompt);
		}

		prompt.on('click.prompt', removePrompt);

	},

	appendGlobalFromAjax(res) {
		let loadedPrompts = $(GLOBAL_PROMPT_SELECTOR, res);
		//si le node n'est pas trouvé avec le selector, il est possible que le node soit au premier niveau du jquery donné
		if(loadedPrompts.length == 0){
			loadedPrompts = $(res).filter(GLOBAL_PROMPT_SELECTOR);
		}

		//select dans la page, et append to si existe, insert si non
		const container = $(GLOBAL_PROMPT_SELECTOR);
		let onDisappear;
		if(container.length){
			onDisappear = disappearGlobalPrompt(container);
		} else {
			loadedPrompts.appendTo('body');
			onDisappear = Promise.resolve();
		}

		onDisappear.then(() => {
			container.html(loadedPrompts.html());
			const h = container.height();
			TweenMax.fromTo(container, ANIM_TIME, { top: h * -1, ease: ease.easeOut }, { top: 0 });
			this.setupGlobalPrompts();
		});
	},

	setupGlobalPrompts() {
		const container = $(GLOBAL_PROMPT_SELECTOR);
		const globalMsgs = $('.global-msg');
		globalMsgs.each((i, el) => {
			const msg = $(el);
			const close = $('.close', msg);
			close.off('.err').on('click.err', () => {
				disappearGlobalPrompt(container);
			});
		});
	}

};
'format es6';
'use strict';

import Prompts from '../utils/Prompts';

function validateEmail(val) {
	return val.match(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);
}

function validateFields(totalValid, inp) {
	inp = $(inp);
	let rule = inp.data('validate');
	const val = inp.val();
	const type = inp.prop('type');
	if(type === 'email' && rule === 'required') {
		rule = 'email';
	}

	let isValid ;
	switch(rule) {
		case 'email':
			isValid = validateEmail(val);
			break;
		default:
			isValid = !!val;
	}

	if(!isValid) {
		Prompts.showElementError(inp, inp.data('validate-msg') || 'error');
	}

	totalValid &= isValid;
	return totalValid;
}


export default {

	checkForm(form) {
		const validating = $('[data-validate]', form).toArray();
		return validating.reduce(validateFields, true);
	}

}
'format es6';
'use strict';

const name = 'jcv';

import $ from 'jquery';
import Promise from 'Promise';
import gsap from 'greensock';

try{ require('source-map-'+'support').install(); } catch(e){}

const ns = window[name] = (window[name] || {});

export default ns;


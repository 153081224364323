'format es6';
'use strict';

import { default as ns } from 'ns';
import { docReady } from './utils/docReady';
import $ from 'jquery';
import Promise from 'Promise';
import selectric from 'selectric';
import gsap from 'greensock';

require('../vendor/jquery.scrollTo.min.js')

import './shop/Shop';
import ShareSnippet from './ShareSnippet';

import '../lagrange/jquery.writedate.js';

selectric();

docReady.then(() => {

	const menu = $('.main-nav');
	$('.logo-ctn .fa-bars').on('click.jcv', function(){
		menu.slideToggle();
	});

	const searchBtn = $('[data-togglesearch]');
	const searchBar = $('.search-bar');
	searchBtn.on('click.jcv', () => {
		searchBar.slideToggle();
	});

	$('[datetime]').writeDate({ lang: ns.lang });

	$('.scrollto').on('click.jcv', e => {
		e.preventDefault();
		$.scrollTo($($(this).attr('href')), 300);
	});

	$('select').selectric();

	//clic sur produit dans liste mene a sa fiche
	// console.log($('[data-list-item]').length);
	const listItems = $('[data-list-item]');
	$('a', listItems).on('click', (e) => e.stopPropagation());
	listItems.on('click.jcv', (e) => {
		const listItem = $(e.currentTarget);
		console.log(listItem);
		const lnk = $('[data-list-item-link]', listItem);
		window.location = lnk.prop('href');
	});

});
